import { CLIENT_ID } from '@insight2profit/drive-app';
import { IDataAccessService, IExportService } from '@price-for-profit/micro-services';
import { format } from 'date-fns/fp';
import {
    ICustomerPriceQuoteLineItem,
    ICustomerPriceQuoteShipToLineItems,
    ICustomerPricesQuote,
    ICustomerPricesQuoteForm,
} from 'shared/components/app/customerPrices/customerPricesQuote/CustomerPricesQuoteForm';
import { DATABASE_LABEL, TABLE_QUOTE_DEFAULTS } from 'shared/constants';
import { ITableQuoteDefaults, IViewCustomerPrices } from 'shared/types';
import { formatCommaSeparatedNumber, formatDate } from 'shared/utility';

type WelcomeMessageParams = {
    businessLine: string;
    region: string;
};

export interface ICustomerPricesQuoteService {
    generateQuote({ customerPricesQuote }: { customerPricesQuote: ICustomerPricesQuote }): Promise<void>;
    mapIViewCustomerPricesToICustomerPriceQuoteLineItem(
        customerPrice: IViewCustomerPrices
    ): ICustomerPriceQuoteLineItem;
    reduceIViewCustomerPricesToICustomerPriceQuoteShipToLineItems(
        customerPrices: IViewCustomerPrices[]
    ): ICustomerPriceQuoteShipToLineItems[];
    mapICustomerPricesQuoteFormToICustomerPricesQuote({
        customerPricesQuoteForm,
        totalValue,
    }: {
        customerPricesQuoteForm: ICustomerPricesQuoteForm;
        totalValue: string;
    }): ICustomerPricesQuote;
    getDefaultsQuoteValues({ businessLine, region }: WelcomeMessageParams): Promise<ITableQuoteDefaults[]>;
}

export class CustomerPricesQuoteService implements ICustomerPricesQuoteService {
    constructor(private exportService: IExportService, private dasService: IDataAccessService) {}

    async generateQuote({ customerPricesQuote }: { customerPricesQuote: ICustomerPricesQuote }) {
        const quoteBlob = await this.exportService.downloadPdf({
            clientId: CLIENT_ID,
            templateId: 'customerpricesquote',
            payload: {
                data: customerPricesQuote,
                // @ts-ignore
                // micro-services-common v1.9.0 is missing types - v2.10.1 will provide the correct types
                header: {
                    templateId: 'customerpricesquoteheader',
                },
                footer: {
                    templateId: 'customerpricesquotefooter',
                },
                marginTop: 35,
                marginBottom: 30,
            },
        });

        const now = new Date();
        const years = now.getFullYear();
        const month = now.getMonth() + 1;
        const day = now.getDate();

        const fileName = `Quote-${customerPricesQuote.customerName}-${years}${month}${day}`;

        this.handleBlob(quoteBlob, fileName);
    }

    reduceIViewCustomerPricesToICustomerPriceQuoteShipToLineItems(customerPrices: IViewCustomerPrices[]) {
        const shipToLineItems = customerPrices.reduce((accum: ICustomerPriceQuoteShipToLineItems[], customerPrice) => {
            const lineItem = this.mapIViewCustomerPricesToICustomerPriceQuoteLineItem(customerPrice);
            const shipToId = customerPrice.shipToId || '';
            const existingShipToId = accum.find(shipToLineItem => shipToLineItem.shipToNumber === shipToId);

            if (existingShipToId) {
                existingShipToId.lineItems.push(lineItem);
            } else {
                accum.push({
                    shipToCustomer: customerPrice.shipTo || '',
                    shipToNumber: shipToId,
                    lineItems: [lineItem],
                });
            }

            return accum;
        }, []);

        return shipToLineItems;
    }

    mapIViewCustomerPricesToICustomerPriceQuoteLineItem(customerPrice: IViewCustomerPrices) {
        const formatDateForForm = (dateStr?: string) => (dateStr ? formatDate(new Date(dateStr)) : '');

        const validFrom = formatDateForForm(customerPrice.validFrom);
        const validTo = formatDateForForm(customerPrice.validTo);
        const amounts: string[] = [
            customerPrice.amount01?.toString() || '',
            customerPrice.amount02?.toString() || '',
            customerPrice.amount03?.toString() || '',
            customerPrice.amount04?.toString() || '',
            customerPrice.amount05?.toString() || '',
            customerPrice.amount06?.toString() || '',
        ].filter(value => value !== '0');

        const scales: (number | null)[] = [
            null,
            customerPrice.scaleQty02 || null,
            customerPrice.scaleQty03 || null,
            customerPrice.scaleQty04 || null,
            customerPrice.scaleQty05 || null,
            customerPrice.scaleQty06 || null,
        ].filter(value => value !== null);

        //Adding the last value to use on hbs file @last to match A/C for values and scales
        if (scales.length > 0) {
            const lastScaleValue = scales[scales.length - 1];
            scales.push(lastScaleValue);
        }

        const lineItem: ICustomerPriceQuoteLineItem = {
            id: customerPrice.id,
            shipToCustomer: customerPrice.shipTo || '',
            shipToNumber: customerPrice.shipToId || '',
            validFrom,
            validTo,
            product: customerPrice?.material || '',
            material: customerPrice?.materialId || '',
            estAnnualVolume: '',
            moq: '',
            price:
                customerPrice?.isScalePrice && scales.length !== 0
                    ? customerPrice?.amount01?.toString() || ''
                    : customerPrice?.revisedPrice?.toString() || '',
            incoterms: customerPrice?.incoterms || '',
            leadTime: '',
            uom: customerPrice?.uom || '',
            currency: customerPrice?.documentCurrency || '',
            perQuantity: formatCommaSeparatedNumber(customerPrice?.perQuantity),
            status: customerPrice?.status || '',
            isScalePrice: customerPrice?.isScalePrice,
            amounts: amounts || [],
            scales: scales || [],
        };
        return lineItem;
    }

    mapICustomerPricesQuoteFormToICustomerPricesQuote({
        customerPricesQuoteForm,
        totalValue,
    }: {
        customerPricesQuoteForm: ICustomerPricesQuoteForm;
        totalValue: string;
    }) {
        let convertedTotalValue = 0;

        const customerPricesQuote: ICustomerPricesQuote = {
            ...customerPricesQuoteForm,
            totalValue,
            dateOfIssue: this.formatDateForQuote(customerPricesQuoteForm.dateOfIssue),
            shipToLineItems: customerPricesQuoteForm.shipToLineItems.map(({ lineItems, ...rest }) => ({
                ...rest,
                lineItems: lineItems.map(lineItem => {
                    const convertedPrice =
                        Number(lineItem.price) * Number(customerPricesQuoteForm.matchingExchangeRate);
                    const formattedValue = new Intl.NumberFormat('en-EN', {
                        style: 'currency',
                        currency: customerPricesQuoteForm.documentCurrency,
                    }).format(convertedPrice);
                    convertedTotalValue += convertedPrice;

                    const formattedAmounts = lineItem.amounts.map(amount => {
                        const convertedAmount = Number(amount) * Number(customerPricesQuoteForm.matchingExchangeRate);
                        return new Intl.NumberFormat('en-EN', {
                            style: 'currency',
                            currency: customerPricesQuoteForm.documentCurrency,
                        }).format(convertedAmount);
                    });

                    return {
                        ...lineItem,
                        price: formattedValue,
                        currency: customerPricesQuoteForm.documentCurrency,
                        validFrom: this.formatDateForQuote(lineItem.validFrom),
                        validTo: this.formatDateForQuote(lineItem.validTo),
                        amounts: formattedAmounts,
                    };
                }),
            })),
        };

        const formattedTotal = new Intl.NumberFormat('en-EN', {
            style: 'currency',
            currency: customerPricesQuoteForm.documentCurrency,
        }).format(convertedTotalValue);
        const customerPricesQuoteWithConvertedTotal: ICustomerPricesQuote = {
            ...customerPricesQuote,
            totalValue: formattedTotal,
        };
        return customerPricesQuoteWithConvertedTotal;
    }

    private handleBlob(blob: Blob, fileName = 'export') {
        const blobUrl = URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', `${fileName}.pdf`);
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href);
        link.parentNode?.removeChild(link);
    }

    private formatDateForQuote(dateStr: string) {
        const formatter = format('MMM d, yyyy');
        return formatter(new Date(dateStr));
    }

    async getDefaultsQuoteValues({ businessLine, region }: WelcomeMessageParams): Promise<ITableQuoteDefaults[]> {
        const { data } = await this.dasService.getCollection<ITableQuoteDefaults, typeof DATABASE_LABEL>({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: TABLE_QUOTE_DEFAULTS,
            page: 0,
            collectionFilter: {
                logicalOperator: 'and',
                filters: [
                    {
                        property: 'businessLine',
                        operator: 'eq',
                        value: businessLine,
                    },
                    {
                        property: 'region',
                        operator: 'eq',
                        value: region,
                    },
                ],
            },
        });

        return data;
    }
}
