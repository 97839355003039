import { useService, useUser } from '@insight2profit/drive-app';
import { DataAccessResponse } from '@price-for-profit/micro-services';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { QUERY_KEYS } from 'shared/constants';
import { usePricesInvalidator } from 'shared/providers';
import { ITableCustomerPricesExceptions, IViewCustomerPrices, IViewCustomerPricesExceptions } from 'shared/types';
import { useCustomerPriceCommentsMutation } from './customerPriceCommentsMutation';
import { useCustomerPricesExceptionsApprovalEmailMutation } from './customerPricesExceptionsApprovalEmailMutation';

export function useCustomerPricesExceptionsApproveMutation() {
    const { customerPricesExceptionsService } = useService();
    const { enqueueSnackbar } = useSnackbar();
    const { invalidateQuery } = usePricesInvalidator();
    const user = useUser();
    const queryClient = useQueryClient();
    const commentMutation = useCustomerPriceCommentsMutation();
    const approvalEmailMutation = useCustomerPricesExceptionsApprovalEmailMutation();

    const mutation = useMutation<
        {
            editResponse: DataAccessResponse<ITableCustomerPricesExceptions>;
            addResponse: DataAccessResponse<ITableCustomerPricesExceptions>;
            newViewRow: IViewCustomerPricesExceptions;
        },
        Error,
        {
            row: IViewCustomerPricesExceptions;
            comment: string;
            customerPricesRow?: IViewCustomerPrices;
        }
    >({
        mutationKey: ['ApproveCustomerPricesExceptions'],
        mutationFn: async ({ row }) => {
            return await customerPricesExceptionsService.approveRow(row, user?.displayName || '');
        },
        onSuccess: async (data, { row, comment, customerPricesRow }) => {
            enqueueSnackbar('Approval submitted', { variant: 'success' });
            invalidateQuery();

            await commentMutation.mutateAsync({
                id: 0,
                businessLine: row.businessLine || '',
                materialId: row.materialId || '',
                orgRegion: row.orgRegion || '',
                application: row.application || '',
                shipToId: row.shipToId || '',
                soldToId: row.soldToId || '',
                comment,
                modifiedBy: user.displayName,
                effectiveStart: data.addResponse.data.effectiveStart,
                effectiveEnd: null,
                deleted: false,
            });

            queryClient.invalidateQueries([QUERY_KEYS.customerPrices]);

            if (customerPricesRow) {
                approvalEmailMutation.mutateAsync({
                    customerPricesExceptionRow: row,
                    customerPricesRow,
                    addResponse: data.addResponse,
                    comment,
                });
            } else {
                enqueueSnackbar('Email failed: Customer Prices Row is missing', { variant: 'error' });
            }
        },
        onError: _error => {
            enqueueSnackbar('There was an error submitting the approval', { variant: 'error' });
        },
    });
    return mutation;
}
