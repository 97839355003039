import { CLIENT_ID } from '@insight2profit/drive-app';
import { IDataAccessService, IFilter, getData } from '@price-for-profit/micro-services';
import { DATABASE_LABEL } from 'shared/constants';
import { VwUsers } from 'shared/types';

export const getUserEmails = async ({
    dasService,
    collectionFilter,
}: {
    dasService: IDataAccessService;
    collectionFilter?: IFilter<VwUsers> | undefined;
}): Promise<string[]> => {
    const users = await getData((page: number) =>
        dasService.getCollection<VwUsers, typeof DATABASE_LABEL>({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: 'vwUsers',
            // das supports projections, but micro-services does not
            // @ts-ignore
            projections: ['displayName', 'email'],
            collectionFilter,
            page,
        })
    );

    const userEmailAddresses = users.map(aCcUser => aCcUser.email);
    return userEmailAddresses.filter(Boolean) as string[];
};

export const getRoleFilter = (approver: string): IFilter<VwUsers> => {
    return approver === 'Product Line Management'
        ? {
              logicalOperator: 'or',
              filters: [
                  {
                      property: 'isRoleProductLineManager',
                      operator: 'eq',
                      value: `1`,
                  },
                  {
                      property: 'isTier4Approver',
                      operator: 'eq',
                      value: `1`,
                  },
              ],
          }
        : {
              property: 'approverRoles',
              operator: 'contains',
              value: `|${approver}|`,
          };
};
