import { useIsDemoMode, useService } from '@insight2profit/drive-app';
import { Alert, Box, FilterOptionsState, Grid, Tooltip, Typography } from '@mui/material';
import { PropsWithChildren, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { FormDateField } from 'shared/components/component-library-forms/form-date-field';
import { FormMoneyField } from 'shared/components/component-library-forms/form-money-field';
import { FormSubmitButton } from 'shared/components/component-library-forms/form-submit-button';
import { FormTextField } from 'shared/components/component-library-forms/form-text-field';
import { SelectInput } from 'shared/components/component-library-forms/select-input';
import { FormExchangeRateField } from 'shared/components/forms/FormExchangeRateField';
import Loading from 'shared/components/routing/loading';
import {
    CUSTOMER_PRICES_PDF_ADDRESS_OPTIONS_DEMO,
    CUSTOMER_PRICES_PDF_BANK_SELECTION_OPTIONS,
    CUSTOMER_PRICES_PDF_BANK_SELECTION_OPTIONS_DEMO,
    CUSTOMER_PRICES_PDF_COMPANY_OPTIONS_DEMO,
    PAYMENT_TERMS,
} from 'shared/constants';
import { useCustomerPricesQuoteMutation } from 'shared/mutations/customerPricesQuoteMutation';
import { useExchangeRates } from 'shared/providers';
import { useCustomerQuoteDefaultQuery } from 'shared/queries';
import { BankDetailsSelectorOption, IViewCustomerPrices } from 'shared/types';
import { formatDate, formatDateTimeSeconds } from 'shared/utility';

export type ICustomerPriceQuoteShipToLineItems = {
    shipToCustomer: string;
    shipToNumber: string;
    lineItems: ICustomerPriceQuoteLineItem[];
};

export type ICustomerPriceQuoteLineItem = {
    id?: string;
    shipToCustomer: string;
    shipToNumber: string;
    product: string;
    material: string;
    validFrom: string;
    validTo: string;
    moq: string;
    leadTime: string;
    price: string;
    currency: string;
    uom: string;
    incoterms: string;
    estAnnualVolume: string;
    perQuantity: string;
    status: string;
    isScalePrice: boolean;
    scales: (number | null)[];
    amounts: (string | null)[];
};

export type ICustomerPricesQuote = ICustomerPricesQuoteForm & {
    totalValue: string;
};

export type ICustomerPricesQuoteForm = {
    customerName: string;
    contactPerson: string;
    contactPersonAddress: string;
    bankDetails: string;
    bankDetailsSelector: BankDetailsSelectorOption;
    paymentTerms: string;
    accountManagerEmail: string;
    orderSubmissionEmail: string;
    dateOfIssue: string;
    quotationNumber: string;
    phoneNumber: string;
    vat: string;
    companyName: string;
    address: string;
    shipToLineItems: ICustomerPriceQuoteShipToLineItems[];
    conditions: string;
    documentCurrency: string;
    matchingExchangeRate: number;
    welcomeMessage: string;
};

interface ICustomerPricesQuoteFormProps {
    allRows: IViewCustomerPrices[];
    isLoading: boolean;
    companyNames: string[] | undefined;
    isFetchingCompanyNames: boolean;
    companyAddresses: string[] | undefined;
    isFetchingCompanyAddresses: boolean;
}

type IViewCustomerPricesWithOrgRegion = IViewCustomerPrices & { orgRegion: string };

export function CustomerPricesQuoteForm({
    allRows,
    isLoading,
    companyNames,
    isFetchingCompanyNames,
    companyAddresses,
    isFetchingCompanyAddresses,
}: ICustomerPricesQuoteFormProps) {
    const allProducts = (allRows?.filter(row => Boolean(row.orgRegion)) ?? []) as IViewCustomerPricesWithOrgRegion[];

    const { customerPricesQuoteService } = useService();
    const { data: quoteDefaultsVerbiage, isLoading: isLoadingDefaults } = useCustomerQuoteDefaultQuery(
        allProducts[0]?.businessLine.toLowerCase(),
        allProducts[0]?.orgRegion.toLowerCase()
    );
    const { exchangeRates } = useExchangeRates();
    const isDemoMode = useIsDemoMode();
    const documentCurrencyOptions = [
        ...(exchangeRates?.data?.map(aCurrencyCode => {
            return aCurrencyCode.fromCurrencyCode;
        }) || []),
    ];

    const { shipToLineItems, hasNotApprovedLineItems } = useMemo(
        () => ({
            shipToLineItems: customerPricesQuoteService.reduceIViewCustomerPricesToICustomerPriceQuoteShipToLineItems(
                allRows
            ),
            hasNotApprovedLineItems: !!allRows.find(item => item.status !== 'Approved'),
        }),
        [allRows, customerPricesQuoteService]
    );

    const defaultForm: ICustomerPricesQuoteForm = useMemo(() => {
        const firstRow = allRows?.[0];
        return {
            customerName: firstRow?.soldTo || '',
            contactPerson: '',
            contactPersonAddress: '',
            paymentTerms: 'I030 - Within 30 days after date of invoice',
            accountManagerEmail: '',
            orderSubmissionEmail: '',
            documentCurrency: 'USD',
            matchingExchangeRate: 1,
            dateOfIssue: formatDate(new Date()),
            quotationNumber: `${firstRow?.soldToId || ''}-${formatDateTimeSeconds(new Date())}`,
            phoneNumber: isDemoMode ? `T +14406469490` : `T +46 31 58 70 00`,
            vat: '',
            companyName: isDemoMode ? 'Insight2Profit' : 'Nouryon Pulp and Performance Chemicals AB',
            address: isDemoMode
                ? `3333 Richmond Rd SUITE200, Beachwood, OH 44122, United States`
                : `Gamlestadsvagen 18 B/C, 415 02 Gothenburg, Sweden`,
            bankDetailsSelector: {} as BankDetailsSelectorOption,
            bankDetails: '',
            shipToLineItems,
            welcomeMessage: quoteDefaultsVerbiage ? quoteDefaultsVerbiage[0]?.quotationSalutation : '',
            conditions: quoteDefaultsVerbiage ? quoteDefaultsVerbiage[0]?.commercialConditions : '',
        };
    }, [allRows, shipToLineItems, isDemoMode, quoteDefaultsVerbiage]);

    const methods = useForm<ICustomerPricesQuoteForm>({ defaultValues: defaultForm });

    const selectedDocumentCurrency = methods.watch('documentCurrency');

    const selectedBankDetails = methods.watch('bankDetailsSelector');

    const findMatchingExchangeRate = useCallback(
        (currencyCode: string) => {
            const foundExchangeRate = exchangeRates?.data?.find(
                anExchangeRate => anExchangeRate.fromCurrencyCode === currencyCode
            );
            return foundExchangeRate ? foundExchangeRate.exchangeRate : 1;
        },
        [exchangeRates?.data]
    );

    useEffect(() => {
        methods.setValue('matchingExchangeRate', findMatchingExchangeRate(selectedDocumentCurrency));
    }, [selectedDocumentCurrency, findMatchingExchangeRate, methods]);

    useEffect(() => {
        methods.reset(defaultForm);
    }, [methods, defaultForm, quoteDefaultsVerbiage]);

    useEffect(() => {
        methods.setValue('bankDetails', getBankDetailsSelectorOptionData(selectedBankDetails, 'textField'));
        methods.clearErrors('bankDetails');
    }, [selectedBankDetails, methods]);

    const customerPricesQuoteMutation = useCustomerPricesQuoteMutation();

    const filterOptions = (options: string[], params: FilterOptionsState<string>) => {
        const filtered = options.filter(option => option?.toLowerCase().includes(params.inputValue.toLowerCase()));

        const { inputValue } = params;

        if (inputValue !== '' && filtered.length === 0) {
            filtered.push(inputValue);
        }

        return filtered;
    };

    return (
        <FormProvider {...methods}>
            <Box
                component='form'
                sx={{ pt: 2 }}
                id={`quote-form`}
                onSubmit={methods.handleSubmit((customerPricesQuoteForm: ICustomerPricesQuoteForm) =>
                    customerPricesQuoteMutation.mutateAsync({ customerPricesQuoteForm, allRows })
                )}
            >
                <QuoteSection pt={0} text='Header'>
                    <Grid item xs={4}>
                        <SelectInput
                            name={'companyName'}
                            {...labelAndRequired('Company Name')}
                            getOptionLabel={(option: string) => option}
                            getOptionSelected={(option: string, value: string) =>
                                option.toLocaleLowerCase() === value.toLocaleLowerCase()
                            }
                            options={isDemoMode ? CUSTOMER_PRICES_PDF_COMPANY_OPTIONS_DEMO : companyNames || []}
                            disableClearable
                            loading={isFetchingCompanyNames}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <SelectInput
                            name={'address'}
                            {...labelAndRequired('Address')}
                            getOptionLabel={(option: string) => option}
                            getOptionSelected={(option: string, value: string) =>
                                option.toLocaleLowerCase() === value.toLocaleLowerCase()
                            }
                            options={isDemoMode ? CUSTOMER_PRICES_PDF_ADDRESS_OPTIONS_DEMO : companyAddresses || []}
                            disableClearable
                            loading={isFetchingCompanyAddresses}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormTextField {...labelAndRequired('Phone Number')} name={'phoneNumber'} />
                    </Grid>
                    <Grid item xs={4}>
                        <SelectInput
                            name={'documentCurrency'}
                            {...labelAndRequired('Quote Currency')}
                            getOptionLabel={(option: string) => option}
                            getOptionSelected={(option: string, value: string) =>
                                option.toLocaleLowerCase() === value.toLocaleLowerCase()
                            }
                            options={documentCurrencyOptions}
                            disableClearable
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormExchangeRateField
                            name={'matchingExchangeRate'}
                            {...labelAndRequired('Exchange Rate')}
                            disabled
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormTextField label='VAT/Tax Number' name={'vat'} />
                    </Grid>
                </QuoteSection>

                <QuoteSection text='Welcome Message'>
                    {isLoading || isLoadingDefaults ? (
                        <Box pt={5} justifyContent={'center'} width={'100%'}>
                            <Loading />
                        </Box>
                    ) : (
                        <Grid item xs={12}>
                            <FormTextField
                                {...labelAndRequired('Welcome Message')}
                                name={'welcomeMessage'}
                                multiline
                                rows={4}
                            />
                        </Grid>
                    )}
                </QuoteSection>

                <QuoteSection text='Quote Information'>
                    {isLoading ? (
                        <Box pt={5} justifyContent={'center'} width={'100%'}>
                            <Loading />
                        </Box>
                    ) : (
                        <>
                            <Grid item xs={8}>
                                <FormTextField {...labelAndRequired('Customer Company Name')} name={'customerName'} />
                            </Grid>
                            <Grid item xs={4}>
                                <FormTextField {...labelAndRequired('Quotation No.')} name={'quotationNumber'} />
                            </Grid>
                            <Grid item xs={4}>
                                <FormTextField {...labelAndRequired('Customer Contact')} name={'contactPerson'} />
                            </Grid>
                            <Grid item xs={4}>
                                <FormTextField
                                    {...labelAndRequired('Contact Details')}
                                    name={'contactPersonAddress'}
                                    multiline
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormDateField {...labelAndRequired('Issue Date')} name={'dateOfIssue'} />
                            </Grid>
                            <Grid item xs={4}>
                                <FormTextField {...labelAndRequired('Account Manager')} name={'accountManagerEmail'} />
                            </Grid>
                            <Grid item xs={4}>
                                <FormTextField
                                    {...labelAndRequired('Order Submission')}
                                    name={'orderSubmissionEmail'}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <SelectInput
                                    name={'paymentTerms'}
                                    {...labelAndRequired('Payment Terms')}
                                    getOptionLabel={(option: string) => option}
                                    getOptionSelected={(option: string, value: string) =>
                                        option.toLocaleLowerCase() === value.toLocaleLowerCase()
                                    }
                                    options={PAYMENT_TERMS}
                                    disableClearable
                                    filterOptions={filterOptions}
                                />
                            </Grid>
                        </>
                    )}
                </QuoteSection>
                <QuoteSection text='Products'>
                    {isLoading ? (
                        <Box pt={5} justifyContent={'center'} width={'100%'}>
                            <Loading />
                        </Box>
                    ) : (
                        <Grid item xs={12}>
                            {shipToLineItems.flatMap(({ shipToCustomer, lineItems }, shipToIndex) =>
                                lineItems.map((lineItem, index) => {
                                    if (lineItem.status !== 'Approved') {
                                        return (
                                            <QuoteSection
                                                key={`quote-product-form-${lineItem.id}`}
                                                pt={index === 0 ? 0 : 4}
                                                text={`${shipToCustomer} - ${lineItem.product}`}
                                            >
                                                <Alert severity='warning' sx={{ mt: 2, mb: 2 }}>
                                                    {lineItem.status === 'Approval Required'
                                                        ? 'Quote can not be generated until approval is made'
                                                        : 'Quote can not be generated due to missing approved price'}
                                                </Alert>
                                            </QuoteSection>
                                        );
                                    }

                                    return (
                                        <QuoteSection
                                            key={`quote-product-form-${lineItem.id}`}
                                            pt={index === 0 ? 0 : 4}
                                            text={`${lineItem.shipToCustomer} - ${lineItem.product}`}
                                        >
                                            <Grid item xs={4}>
                                                <FormTextField
                                                    {...labelAndRequired('Ship To Customer')}
                                                    name={`shipToLineItems.[${shipToIndex}].lineItems.[${index}].shipToCustomer`}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormTextField
                                                    {...labelAndRequired('Ship To Number')}
                                                    name={`shipToLineItems.[${shipToIndex}].lineItems.[${index}].shipToNumber`}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormTextField
                                                    {...labelAndRequired('Per Quantity')}
                                                    name={`shipToLineItems.[${shipToIndex}].lineItems.[${index}].perQuantity`}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormTextField
                                                    {...labelAndRequired('MOQ')}
                                                    name={`shipToLineItems.[${shipToIndex}].lineItems.[${index}].moq`}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormTextField
                                                    {...labelAndRequired('Lead Time')}
                                                    name={`shipToLineItems.[${shipToIndex}].lineItems.[${index}].leadTime`}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormTextField
                                                    {...labelAndRequired('Est. Annual Volume')}
                                                    name={`shipToLineItems.[${shipToIndex}].lineItems.[${index}].estAnnualVolume`}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormTextField
                                                    {...labelAndRequired('UOM')}
                                                    name={`shipToLineItems.[${shipToIndex}].lineItems.[${index}].uom`}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormTextField
                                                    {...labelAndRequired('Currency')}
                                                    name={`documentCurrency`}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormMoneyField
                                                    {...labelAndRequired('Unit Price')}
                                                    name={`shipToLineItems.[${shipToIndex}].lineItems.[${index}].price`}
                                                    currency={selectedDocumentCurrency}
                                                    matchingExchangeRate={Number(
                                                        findMatchingExchangeRate(selectedDocumentCurrency)
                                                    )}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormTextField
                                                    {...labelAndRequired('IncoTerms')}
                                                    name={`shipToLineItems.[${shipToIndex}].lineItems.[${index}].incoterms`}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormDateField
                                                    {...labelAndRequired('Valid From')}
                                                    name={`shipToLineItems.[${shipToIndex}].lineItems.[${index}].validFrom`}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormDateField
                                                    {...labelAndRequired('Valid To')}
                                                    name={`shipToLineItems.[${shipToIndex}].lineItems.[${index}].validTo`}
                                                    disabled
                                                />
                                            </Grid>
                                        </QuoteSection>
                                    );
                                })
                            )}
                        </Grid>
                    )}
                </QuoteSection>
                <QuoteSection text='Bank Details'>
                    <Grid item xs={12}>
                        <SelectInput
                            name={'bankDetailsSelector'}
                            label={'Select Bank Details'}
                            getOptionLabel={option => getBankDetailsSelectorOptionData(option, 'optionLabel')}
                            getOptionSelected={(option, value) =>
                                JSON.stringify(option).toLocaleLowerCase() === JSON.stringify(value).toLocaleLowerCase()
                            }
                            options={
                                isDemoMode
                                    ? CUSTOMER_PRICES_PDF_BANK_SELECTION_OPTIONS_DEMO
                                    : CUSTOMER_PRICES_PDF_BANK_SELECTION_OPTIONS
                            }
                            disableClearable
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormTextField label='Bank Details' name={'bankDetails'} multiline />
                    </Grid>
                </QuoteSection>
                <QuoteSection text='Conditions'>
                    {isLoading || isLoadingDefaults ? (
                        <Box pt={5} justifyContent={'center'} width={'100%'}>
                            <Loading />
                        </Box>
                    ) : (
                        <Grid item xs={12}>
                            <FormTextField label='Conditions' name='conditions' multiline rows={10} />
                        </Grid>
                    )}
                </QuoteSection>
                <Box sx={{ display: 'flex', justifyContent: 'end', height: '2rem', mt: 2 }}>
                    <Tooltip title={hasNotApprovedLineItems && 'Must contain only approved products'}>
                        <div>
                            <FormSubmitButton
                                defaultForm={defaultForm}
                                isLoading={customerPricesQuoteMutation.isLoading || hasNotApprovedLineItems}
                                submitText='Generate Quote'
                            />
                        </div>
                    </Tooltip>
                </Box>
            </Box>
        </FormProvider>
    );
}

const QuoteSection = ({ pt = 4, text, children }: PropsWithChildren<{ text: string; pt?: number }>) => (
    <Box display='flex' flexDirection={'column'} gap={2} pt={pt}>
        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
            {text}
        </Typography>
        <Grid container spacing={2} pl={2}>
            {children}
        </Grid>
    </Box>
);

const labelAndRequired = (label: string) => {
    return {
        label,
        rules: {
            required: `${label} is required`,
        },
    };
};

const getBankDetailsSelectorOptionData = (
    { currency, branchName, accountHolder, accountNumber, swiftCode }: BankDetailsSelectorOption,
    dataType: 'optionLabel' | 'textField'
) => {
    if (!currency) {
        return '';
    }

    const curencyData = `Payment in ${currency}`;
    const branchNameData = branchName;
    const accountHolderData = `Account Holder: ${accountHolder}`;
    const accountNumberData = `IBAN: ${accountNumber}`;
    const swiftcodeData = `SWIFT: ${swiftCode}`;

    switch (dataType) {
        case 'optionLabel':
            return `${curencyData}, ${branchNameData}, ${accountHolderData}, ${accountNumberData}, ${swiftcodeData}`;
        case 'textField':
        default:
            return `${curencyData}
${branchNameData}
${accountHolderData}
${accountNumberData}
${swiftcodeData}`;
    }
};
