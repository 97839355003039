import { useService } from '@insight2profit/drive-app';
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@mui/material';
import { DataGridPremium, GridFilterModel, GridLinkOperator } from '@mui/x-data-grid-premium';
import { useI2pDataGridEdit, useI2pServerDataGrid } from '@price-for-profit/data-grid';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CustomerPricesCustomToolbar } from 'shared/components/app/customerPrices/CustomerPricesCustomToolbar';
import { CustomerPricesExceptionsModal } from 'shared/components/app/customerPrices/customerPricesExceptions/CustomerPricesExceptionModal';
import { CustomerPricesQuoteModal } from 'shared/components/app/customerPrices/customerPricesQuote/CustomerPricesQuoteModal';
import { DATA_GRID_STYLE, HEADER_HEIGHT } from 'shared/constants/dataGrid';
import { QUERY_KEYS } from 'shared/constants/queryKeys';
import { useUserPermissions } from 'shared/hooks';
import { ProvidePricesInvalidator, useExchangeRates } from 'shared/providers';
import { useSelectedCustomerPrice } from 'shared/providers/provideSelectedCustomerPrice';
import { IViewCustomerPrices } from 'shared/types';
import { CustomerPriceDrawer } from './customerPriceDrawer/CustomerPriceDrawer';
import { useCustomerPricesColumns } from './customerPricesResolver';

type CustomerPricesProps = {
    user: app.UserInfo;
};

export function CustomerPrices({ user }: CustomerPricesProps) {
    const [mutationLoading, setMutationLoading] = useState(false);
    const { customerPricesService, customerPricesPersistedAttributesService } = useService();
    const { isForeignCurrency } = useExchangeRates();
    const { customerPricesPermittedRowLevels } = useUserPermissions();
    const { enqueueSnackbar } = useSnackbar();
    const [errorModalMessage, setErrorModalMessage] = useState('');
    const { openDrawer, status } = useSelectedCustomerPrice();
    const dirtyColumns = useCustomerPricesColumns({ openDrawer });
    const [searchParameters] = useSearchParams();
    const queryClient = useQueryClient();

    const idString = searchParameters.get('ids');
    const idArray = idString ? idString.split('|') : [];

    const filterModelItems = idArray.map(anId => {
        return {
            id: anId,
            columnField: 'newRecordId',
            operatorValue: 'contains',
            value: anId,
        };
    });

    const filterModel: GridFilterModel = {
        items: filterModelItems,
    };

    const { getDataGridProps, invalidate, state } = useI2pServerDataGrid<IViewCustomerPrices>({
        columns: dirtyColumns,
        name: QUERY_KEYS.customerPrices,
        getData: async state => {
            return await customerPricesService.getGridData({ state, customerPricesPermittedRowLevels });
        },
        initialState: {
            pageSize: 10,
            sortModel: [{ field: 'material', sort: 'desc' }],
            filterModel: { ...filterModel, linkOperator: GridLinkOperator.Or },
        },
        dataGridInitialState: {
            pinnedColumns: { left: ['__check__', 'shipTo', 'material'] },
            columns: {
                // this is to force datagrid pro to store initial columnVisibilityModel as part of
                // apiRef.current.state so it can be reverted to in the default view from the toolbar
                columnVisibilityModel: {
                    parentId: false,
                    newRecordId: false,
                    orgRegion: false,
                    application: false,
                    accountAssignmentGroup: false,
                    marketSegment: false,
                    applicationSubMarket: false,
                    applicationGroup: false,
                    salesChannel: false,
                    materialType: false,
                    salesOrganization: false,
                    division: false,
                    distributionChannel: false,
                    productReachCategory: false,
                    elasticityCategory: false,
                    agFoodCompositeIndex: false,
                    pmi: false,
                    productPeer: false,
                    purchasingBreadthCategory: false,
                },
            },
        },
        rowsPerPageOptions: [10, 20, 50, 100],
    });

    const { getDataGridEditProps } = useI2pDataGridEdit<IViewCustomerPrices>({
        onEdit: async (newViewRow, oldViewRow) => {
            if (JSON.stringify(oldViewRow) === JSON.stringify(newViewRow)) {
                enqueueSnackbar('No edit made', { variant: 'warning' });
                setMutationLoading(false);

                return oldViewRow;
            }

            setMutationLoading(true);
            let result: IViewCustomerPrices;
            if (
                oldViewRow.documentCurrency !== newViewRow.documentCurrency ||
                oldViewRow.priceTypeAttributes !== newViewRow.priceTypeAttributes ||
                oldViewRow.uom !== newViewRow.uom
            ) {
                const documentCurrencyResult = await customerPricesPersistedAttributesService.softEditPersistedAttributes(
                    {
                        isForeignCurrency,
                        newViewRow,
                        oldViewRow,
                        userDisplayName: user?.displayName,
                        userEmail: user?.email || '',
                    }
                );
                result = documentCurrencyResult.newViewRow;
            } else {
                const customerPricesResult = await customerPricesService.softEditGridRowData({
                    isForeignCurrency,
                    newViewRow,
                    oldViewRow,
                    userDisplayName: user?.displayName,
                    userEmail: user?.email || '',
                    queryClient,
                });
                result = customerPricesResult.newViewRow;
            }

            invalidate();
            enqueueSnackbar('Edit saved', { variant: 'success' });
            setErrorModalMessage('');
            setMutationLoading(false);

            return result;
        },
        onError: (error: Error) => {
            invalidate();
            enqueueSnackbar(`Edit failed: ${error.message}`, { variant: 'error' });
            setErrorModalMessage(error.message);
        },
    });

    const dataGridProps = getDataGridProps();
    const dataGridEditProps = getDataGridEditProps();

    const handleProcessRowUpdateError = (error: Error) => {
        enqueueSnackbar(`${error.message}`, { variant: 'error' });
    };

    return (
        <Box height='calc(100vh - 300px)'>
            <ProvidePricesInvalidator invalidateQuery={invalidate}>
                <DataGridPremium
                    {...dataGridProps}
                    {...dataGridEditProps}
                    sx={DATA_GRID_STYLE}
                    disableSelectionOnClick
                    components={{
                        Toolbar: CustomerPricesCustomToolbar,
                        LoadingOverlay: LinearProgress,
                    }}
                    componentsProps={{
                        panel: {
                            sx: {
                                '& .MuiDataGrid-filterFormColumnInput': {
                                    width: 'auto',
                                },
                            },
                        },
                        toolbar: {
                            state,
                            invalidate,
                            rowCount: dataGridProps.rowCount,
                            mutationLoading,
                        },
                    }}
                    onRowEditStart={() => setMutationLoading(true)}
                    onRowEditStop={() => setMutationLoading(false)}
                    editMode='row'
                    disableVirtualization={false}
                    onProcessRowUpdateError={handleProcessRowUpdateError}
                    headerHeight={HEADER_HEIGHT}
                    getRowId={row => row.id}
                    isCellEditable={params => {
                        if (regionalCurrencyNotEditable.includes(params.field)) {
                            return !isForeignCurrency;
                        }
                        return true;
                    }}
                />
            </ProvidePricesInvalidator>
            <CustomerPriceDrawer />
            <CustomerPricesExceptionsModal />
            <CustomerPricesQuoteModal
                key={`quote-modal-${status?.toString() || 'none'}`}
                state={state}
                rows={dataGridProps.rows as IViewCustomerPrices[]}
            />
            <Dialog onClose={() => setErrorModalMessage('')} open={!!errorModalMessage}>
                <DialogTitle> Edit not allowed! </DialogTitle>
                <DialogContent>
                    <Alert severity='warning'>{errorModalMessage}</Alert>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setErrorModalMessage('')}> OK </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

const regionalCurrencyNotEditable = ['documentCurrency', 'uom'];
