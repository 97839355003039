import { ImmutableObject } from '@hookstate/core';
import { useService, useUser } from '@insight2profit/drive-app';
import {
    DataAccessResponse,
    EventData,
    HtmlNotificationEvent,
    IDataAccessService,
} from '@price-for-profit/micro-services';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { customerPriceStatuses, TEMPLATE_ID_EXCEPTION_PRICE_SUBMIT_EMAIL } from 'shared/constants';
import { IExchangeRates, useExchangeRates } from 'shared/providers';
import {
    ExceptionPriceSubmitEmailData,
    ITableCustomerPricesExceptions,
    IViewCustomerPrices,
    IViewCustomerPricesExceptions,
} from 'shared/types';
import { getRoleFilter, getUserEmails } from 'shared/utility/emails';

export function useCustomerPricesExceptionsSubmitEmailMutation() {
    const { emailService, dasService } = useService();
    const { enqueueSnackbar } = useSnackbar();
    const { exchangeRates } = useExchangeRates();
    const user = useUser();
    const mutation = useMutation<
        EventData,
        Error,
        {
            customerPricesExceptionRow: IViewCustomerPricesExceptions;
            addResponse: DataAccessResponse<ITableCustomerPricesExceptions>;
            customerPricesRow: IViewCustomerPrices;
            comment: string;
        }
    >({
        mutationKey: ['CustomerPricesExceptionSubmitEmail'],
        mutationFn: async ({ customerPricesExceptionRow, addResponse, comment, customerPricesRow }) => {
            const htmlNotificationEvent = await generateCustomerPricesExceptionsSubmitEmailHtmlNotificationEvent({
                addResponse,
                customerPricesRow,
                customerPricesExceptionRow,
                user,
                comment,
                exchangeRates,
                dasService,
            });

            return await emailService.sendHtmlEmail<ExceptionPriceSubmitEmailData>(htmlNotificationEvent);
        },
        onSuccess: () => {
            enqueueSnackbar('Approval email sent submitted', { variant: 'success' });
        },
        onError: error => {
            enqueueSnackbar('Unable to send approval email', { variant: 'error' });
        },
    });
    return mutation;
}

const generateCustomerPricesExceptionsSubmitEmailHtmlNotificationEvent = async ({
    addResponse,
    customerPricesRow,
    customerPricesExceptionRow,
    user,
    comment,
    exchangeRates,
    dasService,
}: {
    addResponse: DataAccessResponse<ITableCustomerPricesExceptions>;
    customerPricesRow: IViewCustomerPrices;
    customerPricesExceptionRow: IViewCustomerPricesExceptions;
    user: ImmutableObject<app.UserInfo>;
    comment: string;
    exchangeRates: IExchangeRates;
    dasService: IDataAccessService;
}): Promise<HtmlNotificationEvent<ExceptionPriceSubmitEmailData>> => {
    const { emailData, approver } = mapExceptionPricesSubmitEmailData({
        addResponse,
        customerPricesRow,
        customerPricesExceptionRow,
        user,
        comment,
        exchangeRates,
    });

    const ccPromise = getSubmitCCEmails({
        dasService,
        businessLine: emailData.customerPricesExceptions[0].businessLine,
        orgRegion: emailData.customerPricesExceptions[0].orgRegion,
    });

    const toPromise = getSubmitToEmails({
        dasService,
        businessLine: emailData.customerPricesExceptions[0].businessLine,
        orgRegion: emailData.customerPricesExceptions[0].orgRegion,
        approver,
    });

    const [ccSettled, toSettled] = await Promise.allSettled([ccPromise, toPromise]);

    if (ccSettled.status === 'rejected') {
        throw Error('Unable to get CC emails for the customer price exception');
    }

    if (toSettled.status === 'rejected') {
        throw Error('Unable to get TO emails for the customer price exception');
    }

    const to = toSettled.value;
    const cc = [user.email, ...ccSettled.value];
    const subject = `Urgent - Sales Order Approval Required`;

    return {
        templateId: TEMPLATE_ID_EXCEPTION_PRICE_SUBMIT_EMAIL,
        to,
        cc,
        subject,
        data: emailData,
    };
};

const getSubmitCCEmails = async ({
    dasService,
    businessLine,
    orgRegion,
}: {
    dasService: IDataAccessService;
    businessLine: string;
    orgRegion: string;
}): Promise<string[]> => {
    return getUserEmails({
        dasService,
        collectionFilter: {
            logicalOperator: 'and',
            filters: [
                {
                    property: 'email',
                    operator: 'isnotnull',
                    value: '',
                },
                {
                    property: 'businessLines',
                    operator: 'contains',
                    value: `|${businessLine}|`,
                },
                {
                    property: 'orgRegions',
                    operator: 'contains',
                    value: `|${orgRegion}|`,
                },
                {
                    property: 'isRoleSalesSupport',
                    operator: 'eq',
                    value: '1',
                },
            ],
        },
    });
};

const getSubmitToEmails = async ({
    dasService,
    businessLine,
    orgRegion,
    approver,
}: {
    dasService: IDataAccessService;
    businessLine: string;
    orgRegion: string;
    approver: string;
}): Promise<string[]> => {
    const roleFilter = getRoleFilter(approver);
    return getUserEmails({
        dasService,
        collectionFilter: {
            logicalOperator: 'and',
            filters: [
                {
                    property: 'email',
                    operator: 'isnotnull',
                    value: '',
                },
                {
                    property: 'businessLines',
                    operator: 'contains',
                    value: `|${businessLine}|`,
                },
                {
                    property: 'orgRegions',
                    operator: 'contains',
                    value: `|${orgRegion}|`,
                },
                roleFilter,
            ],
        },
    });
};

const mapExceptionPricesSubmitEmailData = ({
    addResponse,
    customerPricesRow,
    customerPricesExceptionRow,
    user,
    comment,
    exchangeRates,
}: {
    addResponse: DataAccessResponse<ITableCustomerPricesExceptions>;
    customerPricesRow: IViewCustomerPrices;
    customerPricesExceptionRow: IViewCustomerPricesExceptions;
    user: ImmutableObject<app.UserInfo>;
    comment: string;
    exchangeRates: IExchangeRates;
}): {
    emailData: ExceptionPriceSubmitEmailData;
    approver: string;
} => {
    const statuses = [customerPriceStatuses.APPROVAL_REQUIRED];

    const {
        businessLine,
        soldToId,
        shipToId,
        soldTo,
        shipTo,
        material,
        materialId,
        orgRegion,
        marketSegment,
        application,
        perQuantity,
        salesOrganization,
    } = customerPricesRow;

    const { status, exceptionType, salesOrder, documentCurrency, uom, revisedPrice, approver } = addResponse.data;

    const { lastInvoiceFreightCost, recommendedPrice } = customerPricesExceptionRow;

    const { displayName, email } = user;

    if (!email) {
        throw new Error('Email failed: No email address found for the customer price exception submitter');
    }
    if (!status) {
        throw new Error('Email failed: No status found for the customer price exception');
    }
    if (!statuses.includes(status)) {
        throw new Error('Email failed: Invalid status found for the customer price exception');
    }

    if (!displayName) {
        throw new Error('Email failed: No submitter found for the customer price exception');
    }

    if (!approver) {
        throw new Error('Email failed: No approver found for the customer price exception');
    }

    const revisedPricePerQuantityWithoutFreight = (revisedPrice ?? 0) / perQuantity - (lastInvoiceFreightCost ?? 0);
    const currencyCode = documentCurrency || 'USD';

    const matchingExchangeRate = exchangeRates?.data?.find(
        anExchangeRate => anExchangeRate.fromCurrencyCode === currencyCode
    )?.exchangeRate;

    const revisedPricePerQuantityWithoutFreightInDocCurrency =
        revisedPricePerQuantityWithoutFreight * (matchingExchangeRate ?? 1);
    const recommendedPriceInDocCurrency = recommendedPrice ? recommendedPrice * (matchingExchangeRate ?? 1) : undefined;

    const documentCurrencyFormatter = new Intl.NumberFormat('en-EN', {
        style: 'currency',
        currency: currencyCode,
    });

    const formattedRevisedPricePerQuantityWithoutFreightInDocCurrency = isNaN(
        revisedPricePerQuantityWithoutFreightInDocCurrency
    )
        ? ''
        : documentCurrencyFormatter.format(revisedPricePerQuantityWithoutFreightInDocCurrency);
    const formattedRecommendedPrice = !!recommendedPriceInDocCurrency
        ? documentCurrencyFormatter.format(recommendedPriceInDocCurrency)
        : '';

    return {
        approver,
        emailData: {
            approver,
            orgRegion,
            envUrl: `${window.location.origin}/apps/pmt/customer-prices`,
            customerPricesExceptions: [
                {
                    businessLine: businessLine || '',
                    soldToId: soldToId || '',
                    shipToId: shipToId || '',
                    soldTo: soldTo || '',
                    shipTo: shipTo || '',
                    material: material || '',
                    materialId: materialId || '',
                    orgRegion: orgRegion || '',
                    salesOrganization: salesOrganization || '',
                    marketSegment: marketSegment || '',
                    application: application || '',
                    exceptionType,
                    salesOrder: salesOrder || '',
                    documentCurrency: currencyCode,
                    uom: uom || 'KG',
                    perQuantity: perQuantity.toFixed(0),
                    revisedPriceWithoutFreight: formattedRevisedPricePerQuantityWithoutFreightInDocCurrency,
                    recommendedPrice: formattedRecommendedPrice,
                    submittedBy: displayName,
                    comment,
                },
            ],
        },
    };
};
