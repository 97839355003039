import { ImmutableObject } from '@hookstate/core';
import { CLIENT_ID } from '@insight2profit/drive-app';
import { ServerSideState } from '@price-for-profit/data-grid';
import {
    DataAccessPaginatedResponse,
    DataAccessResponse,
    IDataAccessService,
    NoInfer,
} from '@price-for-profit/micro-services';
import { QueryClient } from '@tanstack/react-query';
import {
    DATABASE_LABEL,
    TABLE_CUSTOMER_PRICES_EXCEPTIONS,
    VIEW_CUSTOMER_PRICES_EXCEPTIONS,
    customerPriceStatuses,
} from 'shared/constants';
import {
    CustomerPriceUniqueIdentifiers,
    ITableCustomerPricesExceptions,
    IViewCustomerPricesExceptions,
} from 'shared/types';
import { ICustomerPriceApprovalService } from './customerPriceApprovalService';
import { IExchangeRatesService } from './exchangeRatesService';
import { IUomConversionService } from './uomConversionService';

type GetGridDataParams = {
    state: ServerSideState;
    customerPriceUniqueIdentifiers: CustomerPriceUniqueIdentifiers;
};

type SoftEditGridRowDataParams = {
    newViewRow: IViewCustomerPricesExceptions;
    oldViewRow: IViewCustomerPricesExceptions;
    userDisplayName?: string;
    userEmail: string;
    isForeignCurrency?: boolean;
    queryClient?: QueryClient;
};

type SoftAddGridRowDataParams = {
    newViewRow: IViewCustomerPricesExceptions;
};

type StatusModifierParams =
    | {
          action: 'approve' | 'reject';
          payload: {
              userDisplayName: string;
              now: string;
              currentStatus: string;
          };
      }
    | {
          action: 'edit';
          payload: {
              userDisplayName: string;
              userEmail: string;
              now: string;
              currentStatus: string;
          };
      }
    | {
          action: 'submit';
          payload: {
              userDisplayName: string;
              userEmail: string;
              now: string;
              currentStatus: string;
              row: IViewCustomerPricesExceptions;
              userApproverTier: number;
          };
      };

type StatusModifierResponse = Partial<ITableCustomerPricesExceptions>;

export interface ICustomerPricesExceptionsService {
    getGridData({
        state,
        customerPriceUniqueIdentifiers,
    }: GetGridDataParams): Promise<DataAccessPaginatedResponse<IViewCustomerPricesExceptions>>;
    softEditGridRowData({
        newViewRow,
        oldViewRow,
        userDisplayName,
        userEmail,
        isForeignCurrency,
    }: SoftEditGridRowDataParams): Promise<{
        editResponse: DataAccessResponse<ITableCustomerPricesExceptions>;
        addResponse: DataAccessResponse<ITableCustomerPricesExceptions>;
        newViewRow: IViewCustomerPricesExceptions;
    }>;
    softAddGridRowData({
        newViewRow,
    }: SoftAddGridRowDataParams): Promise<{
        addResponse: DataAccessResponse<ITableCustomerPricesExceptions>;
    }>;
    submitRow(
        oldViewRow: IViewCustomerPricesExceptions,
        userApproverTier: number,
        user: ImmutableObject<app.UserInfo>
    ): Promise<{
        editResponse: DataAccessResponse<ITableCustomerPricesExceptions>;
        addResponse: DataAccessResponse<ITableCustomerPricesExceptions>;
        newViewRow: IViewCustomerPricesExceptions;
    }>;
    approveRow(
        oldViewRow: IViewCustomerPricesExceptions,
        userDisplayName: string
    ): Promise<{
        editResponse: DataAccessResponse<ITableCustomerPricesExceptions>;
        addResponse: DataAccessResponse<ITableCustomerPricesExceptions>;
        newViewRow: IViewCustomerPricesExceptions;
    }>;
    rejectRow(
        oldViewRow: IViewCustomerPricesExceptions,
        userDisplayName: string
    ): Promise<{
        editResponse: DataAccessResponse<ITableCustomerPricesExceptions>;
        addResponse: DataAccessResponse<ITableCustomerPricesExceptions>;
        newViewRow: IViewCustomerPricesExceptions;
    }>;
    getConvertUomToUom(
        oldUom?: string,
        newUom?: string,
        materialId?: string,
        queryClient?: QueryClient
    ): Promise<(uomMoney?: number) => number | undefined>;
}

export class CustomerPricesExceptionsService implements ICustomerPricesExceptionsService {
    constructor(
        private dasService: IDataAccessService,
        private customerPriceApprovalService: ICustomerPriceApprovalService,
        private uomConversionService: IUomConversionService,
        private exchangeRatesService: IExchangeRatesService
    ) {}

    async getGridData({
        state,
        customerPriceUniqueIdentifiers,
    }: GetGridDataParams): Promise<DataAccessPaginatedResponse<IViewCustomerPricesExceptions>> {
        return await this.dasService.getCollection<IViewCustomerPricesExceptions, typeof DATABASE_LABEL>({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: VIEW_CUSTOMER_PRICES_EXCEPTIONS,
            page: state.pageNumber,
            pageSize: state.pageSize,
            sortBy: state.sortModel[0]?.field as NoInfer<keyof IViewCustomerPricesExceptions>,
            sortDescending: state.sortModel[0]?.sort === 'desc',
            collectionFilter: {
                logicalOperator: 'and',
                filters: [
                    {
                        property: 'businessLine',
                        operator: 'eq',
                        value: customerPriceUniqueIdentifiers.businessLine,
                    },
                    {
                        property: 'materialId',
                        operator: 'eq',
                        value: customerPriceUniqueIdentifiers.materialId,
                    },
                    {
                        property: 'shipToId',
                        operator: 'eq',
                        value: customerPriceUniqueIdentifiers.shipToId,
                    },
                    {
                        property: 'soldToId',
                        operator: 'eq',
                        value: customerPriceUniqueIdentifiers.soldToId,
                    },
                ],
            },
        });
    }

    async softEditGridRowData({
        newViewRow,
        oldViewRow,
        userDisplayName,
        userEmail,
        isForeignCurrency,
        queryClient,
    }: SoftEditGridRowDataParams): Promise<{
        editResponse: DataAccessResponse<ITableCustomerPricesExceptions>;
        addResponse: DataAccessResponse<ITableCustomerPricesExceptions>;
        newViewRow: IViewCustomerPricesExceptions;
    }> {
        const now = new Date().toISOString();

        const oldTableRow: ITableCustomerPricesExceptions = await this.getOldTableRow(oldViewRow.massActionId);

        const modifiedNewTableRow = await this.createNewRow({
            isForeignCurrency,
            newViewRow,
            oldTableRow,
            userDisplayName: userDisplayName || '',
            now,
            statusModifierParams: {
                action: 'edit',
                payload: {
                    userDisplayName: userDisplayName || '',
                    userEmail,
                    now,
                    currentStatus: oldTableRow.status || '',
                },
            },
            queryClient,
        });

        await this.softEditValidation({ newTableRow: modifiedNewTableRow });

        const editResponse = await this.termOldTableRow(oldTableRow, now);

        const addResponse = await this.addNewRow(modifiedNewTableRow);

        return {
            editResponse,
            addResponse,
            newViewRow,
        };
    }

    async softAddGridRowData({
        newViewRow,
    }: SoftAddGridRowDataParams): Promise<{
        addResponse: DataAccessResponse<ITableCustomerPricesExceptions>;
    }> {
        await this.softEditValidation({ newTableRow: newViewRow });

        const addResponse = await this.addNewRow({ ...newViewRow, deleted: false });

        return {
            addResponse,
        };
    }

    async submitRow(
        updatedViewRow: IViewCustomerPricesExceptions,
        userApproverTier: number,
        user: ImmutableObject<app.UserInfo>
    ): Promise<{
        editResponse: DataAccessResponse<ITableCustomerPricesExceptions>;
        addResponse: DataAccessResponse<ITableCustomerPricesExceptions>;
        newViewRow: IViewCustomerPricesExceptions;
    }> {
        const now = new Date().toISOString();

        const oldTableRow: ITableCustomerPricesExceptions = await this.getOldTableRow(updatedViewRow.massActionId);

        const modifiedNewTableRow = await this.createNewRow({
            newViewRow: updatedViewRow,
            oldTableRow,
            userDisplayName: user?.displayName || '',
            now,
            statusModifierParams: {
                action: 'submit',
                payload: {
                    userDisplayName: user?.displayName || '',
                    userEmail: user?.email || '',
                    now,
                    currentStatus: oldTableRow.status || '',
                    userApproverTier,
                    row: updatedViewRow,
                },
            },
        });

        const editResponse = await this.termOldTableRow(oldTableRow, now);

        const addResponse = await this.addNewRow(modifiedNewTableRow);

        const newViewRow = {
            ...updatedViewRow,
            ...modifiedNewTableRow,
        };

        return { editResponse, addResponse, newViewRow };
    }

    async approveRow(
        oldViewRow: IViewCustomerPricesExceptions,
        userDisplayName: string
    ): Promise<{
        editResponse: DataAccessResponse<ITableCustomerPricesExceptions>;
        addResponse: DataAccessResponse<ITableCustomerPricesExceptions>;
        newViewRow: IViewCustomerPricesExceptions;
    }> {
        const now = new Date().toISOString();

        const oldTableRow: ITableCustomerPricesExceptions = await this.getOldTableRow(oldViewRow.massActionId);

        const modifiedNewTableRow: ITableCustomerPricesExceptions = await this.createNewRow({
            newViewRow: oldViewRow,
            oldTableRow,
            now,
            userDisplayName,
            statusModifierParams: {
                action: 'approve',
                payload: {
                    userDisplayName: userDisplayName || '',
                    now,
                    currentStatus: oldTableRow.status || '',
                },
            },
        });

        const editResponse = await this.termOldTableRow(oldTableRow, now);

        const addResponse = await this.addNewRow(modifiedNewTableRow);

        const newViewRow = {
            ...oldViewRow,
            ...modifiedNewTableRow,
        };

        return { editResponse, addResponse, newViewRow };
    }

    async rejectRow(
        oldViewRow: IViewCustomerPricesExceptions,
        userDisplayName: string
    ): Promise<{
        editResponse: DataAccessResponse<ITableCustomerPricesExceptions>;
        addResponse: DataAccessResponse<ITableCustomerPricesExceptions>;
        newViewRow: IViewCustomerPricesExceptions;
    }> {
        const now = new Date().toISOString();

        const oldTableRow: ITableCustomerPricesExceptions = await this.getOldTableRow(oldViewRow.massActionId);

        const modifiedNewTableRow: ITableCustomerPricesExceptions = await this.createNewRow({
            newViewRow: oldViewRow,
            oldTableRow,
            now,
            userDisplayName,
            statusModifierParams: {
                action: 'reject',
                payload: {
                    userDisplayName: userDisplayName || '',
                    now,
                    currentStatus: oldTableRow.status || '',
                },
            },
        });

        const editResponse = await this.termOldTableRow(oldTableRow, now);

        const addResponse = await this.addNewRow(modifiedNewTableRow);

        const newViewRow = {
            ...oldViewRow,
            ...modifiedNewTableRow,
        };

        return { editResponse, addResponse, newViewRow };
    }

    private async createNewRow({
        newViewRow,
        userDisplayName,
        now,
        statusModifierParams,
        oldTableRow,
        isForeignCurrency,
        queryClient,
    }: {
        newViewRow: IViewCustomerPricesExceptions;
        oldTableRow: ITableCustomerPricesExceptions;
        userDisplayName: string;
        now: string;
        statusModifierParams: StatusModifierParams;
        isForeignCurrency?: boolean;
        queryClient?: QueryClient;
    }) {
        const isUomChange = oldTableRow.uom !== newViewRow.uom;

        const editableFields: Partial<ITableCustomerPricesExceptions> = {
            exceptionType: newViewRow.exceptionType,
            salesOrder: newViewRow.salesOrder,
            revisedPrice: newViewRow.revisedPrice,
            documentCurrency: newViewRow.documentCurrency,
            validFrom: newViewRow.validFrom,
            validTo: newViewRow.validTo,
        };

        const newTableRow: ITableCustomerPricesExceptions = {
            ...oldTableRow,
            ...(isUomChange ? await this.uomChangeFields(newViewRow, oldTableRow, queryClient) : editableFields),
        };

        const statusModifier = this.statusModifier(statusModifierParams);

        if (isForeignCurrency) {
            const currentDocumentCurrencyCode = newViewRow.documentCurrency;
            const exchangeRatesResponse = await this.exchangeRatesService.getExchangeRates();

            const matchingExchangeRate = exchangeRatesResponse?.data?.find(anExchangeRate => {
                return anExchangeRate.fromCurrencyCode === currentDocumentCurrencyCode;
            });
            if (!matchingExchangeRate) {
                throw new Error(`A matching exchange rate was not found for ${currentDocumentCurrencyCode}`);
            }

            const modifiedNewTableRow: ITableCustomerPricesExceptions = {
                ...newTableRow,
                ...statusModifier,
                massActionId: 0,
                modifiedBy: userDisplayName,
                effectiveStart: now,
                effectiveEnd: undefined,
                deleted: false,
            };
            return modifiedNewTableRow;
        }

        const modifiedNewTableRow: ITableCustomerPricesExceptions = {
            ...newTableRow,
            ...statusModifier,
            massActionId: 0,
            modifiedBy: userDisplayName,
            effectiveStart: now,
            effectiveEnd: undefined,
            deleted: false,
        };
        return modifiedNewTableRow;
    }

    private async uomChangeFields(
        newViewRow: IViewCustomerPricesExceptions,
        oldTableRow: ITableCustomerPricesExceptions,
        queryClient?: QueryClient
    ) {
        const convertUomToUom = await this.uomConversionService.getConverterForStandardAndNonStandardUom(
            oldTableRow.uom,
            newViewRow.uom,
            newViewRow.materialId,
            queryClient
        );

        let revisedPrice;
        if (newViewRow.revisedPrice === oldTableRow.revisedPrice) {
            revisedPrice = convertUomToUom(newViewRow.revisedPrice);
        } else {
            revisedPrice = newViewRow.revisedPrice;
        }

        const result: Partial<ITableCustomerPricesExceptions> = {
            revisedPrice,
            uom: newViewRow.uom,
        };
        return result;
    }

    async getConvertUomToUom(oldUom?: string, newUom?: string, materialId?: string, queryClient?: QueryClient) {
        return await this.uomConversionService.getConverterForStandardAndNonStandardUom(
            oldUom,
            newUom,
            materialId,
            queryClient
        );
    }

    private async addNewRow(newTableRow: ITableCustomerPricesExceptions) {
        return await this.dasService.addRow<ITableCustomerPricesExceptions, typeof DATABASE_LABEL>({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: TABLE_CUSTOMER_PRICES_EXCEPTIONS,
            payload: newTableRow,
        });
    }

    private async termOldTableRow(
        oldTableRow: ITableCustomerPricesExceptions,
        now: string
    ): Promise<DataAccessResponse<ITableCustomerPricesExceptions>> {
        return await this.dasService.updateRow<ITableCustomerPricesExceptions, typeof DATABASE_LABEL>({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: TABLE_CUSTOMER_PRICES_EXCEPTIONS,
            payload: {
                ...oldTableRow,
                effectiveEnd: now,
            },
        });
    }

    private async getOldTableRow(massActionId: number): Promise<ITableCustomerPricesExceptions> {
        const { data: oldTableRow } = await this.dasService.getSingle<
            ITableCustomerPricesExceptions,
            typeof DATABASE_LABEL
        >({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: TABLE_CUSTOMER_PRICES_EXCEPTIONS,
            key: massActionId.toString(),
        });

        if (!oldTableRow || !!oldTableRow.effectiveEnd || !!oldTableRow.deleted) {
            throw new Error('Data not valid. Please refresh the page and try again.');
        }

        return oldTableRow;
    }

    private async softEditValidation({
        newTableRow,
    }: {
        newTableRow: ITableCustomerPricesExceptions | IViewCustomerPricesExceptions;
    }) {
        if (!newTableRow.modifiedBy) throw new Error('User display name not found');

        if (newTableRow?.revisedPrice === null || newTableRow?.revisedPrice === undefined) {
            throw new Error('New Revised Price is missing');
        }

        if (newTableRow.revisedPrice <= 0) {
            throw new Error('Revised Price must be greater than 0');
        }
    }

    private statusModifier({ action, payload }: StatusModifierParams): StatusModifierResponse {
        switch (action) {
            case 'edit':
                return {
                    status: customerPriceStatuses.NEEDS_REVIEW,
                    editedBy: payload.userDisplayName,
                    editedDate: payload.now,
                    editedByEmail: payload.userEmail,
                    approvedBy: undefined,
                    approvedDate: undefined,
                    submittedBy: undefined,
                    submittedByEmail: undefined,
                    submittedByDate: undefined,
                };
            case 'submit':
                if (
                    payload.currentStatus === customerPriceStatuses.NEEDS_REVIEW ||
                    payload.currentStatus === customerPriceStatuses.NO_CHANGE
                ) {
                    const { isAutoApproved, approver } = this.customerPriceApprovalService.getSubmitStatus({
                        row: payload.row,
                        userApproverTier: payload.userApproverTier,
                    });
                    if (isAutoApproved) {
                        return {
                            status: customerPriceStatuses.APPROVED,
                            approver,
                            approvedBy: payload.userDisplayName,
                            approvedDate: payload.now,
                            submittedBy: payload.userDisplayName,
                            submittedByEmail: payload.userEmail,
                            submittedByDate: payload.now,
                        };
                    }
                    return {
                        status: customerPriceStatuses.APPROVAL_REQUIRED,
                        approver,
                        approvedBy: undefined,
                        approvedDate: undefined,
                        submittedBy: payload.userDisplayName,
                        submittedByEmail: payload.userEmail,
                        submittedByDate: payload.now,
                    };
                }
                throw Error('Invalid submit');
            case 'approve':
                return {
                    status: customerPriceStatuses.APPROVED,
                    approvedBy: payload.userDisplayName,
                    approvedDate: payload.now,
                };
            case 'reject':
                return {
                    status: customerPriceStatuses.REJECTED,
                    approvedBy: undefined,
                    approvedDate: undefined,
                };
            default:
                throw Error('Invalid status change');
        }
    }
}
