import { Box } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid-premium';
import { SaveButton } from 'shared/components/forms';
import { customerPriceStatuses } from 'shared/constants';
import { useUserPermissions } from 'shared/hooks';
import { useCustomerPricesExceptionsSubmitMutation } from 'shared/mutations';
import { useCustomerPricesExceptionsSubmitEmailMutation } from 'shared/mutations/customerPricesExceptionsSubmitEmailMutation';
import { IViewCustomerPrices, IViewCustomerPricesExceptions } from 'shared/types';

export function CustomerPricesExceptionsSubmitAction({
    params,
    customerPricesRow,
}: {
    params: GridRowParams<IViewCustomerPricesExceptions>;
    customerPricesRow?: IViewCustomerPrices;
}) {
    const { onSubmit, isLoading } = useCustomerPricesExceptionsSubmitMutation({ customerPricesRow });
    const { isLoading: isEmailMutationLoading } = useCustomerPricesExceptionsSubmitEmailMutation();
    const permissions = useUserPermissions();

    if (params.row.status === 'Approved') return <Box>Submitted</Box>;

    const isSubmitAllowed =
        params.row.status === customerPriceStatuses.NEEDS_REVIEW ||
        params.row.status === customerPriceStatuses.NO_CHANGE ||
        params.row.status === customerPriceStatuses.APPROVED;

    return (
        <SaveButton
            submitHandler={() => {
                onSubmit(params.row);
            }}
            isLoading={isLoading || isEmailMutationLoading}
            isDisabled={!isSubmitAllowed || !permissions.data.isCustomerExceptionsWriteAllowed}
            text='Submit'
        />
    );
}
